<template>
  <div v-if="!isIframe">
    <section class="seal-tabs">
      <a-tabs v-model:activeKey="activeKey">
        <a-tab-pane key="0" tab="全部" force-render> </a-tab-pane>
        <a-tab-pane key="1" tab="待我处理"> </a-tab-pane>
        <a-tab-pane key="2" tab="待他人处理"> </a-tab-pane>
        <a-tab-pane key="3" tab="已完成"> </a-tab-pane>
        <a-tab-pane key="4" tab="已失效"> </a-tab-pane>
      </a-tabs>
    </section>

    <!-- 搜索 -->
    <section style="margin: 0 24px" class="form-style">
      <a-form layout="inline">
        <a-form-item label="" @click="clickFocus">
          <a-input
            class="form-input_365px"
            allowClear
            :maxlength="30"
            v-model:value="searchVal"
            placeholder="请输入流程主题/编号/接收方/接收方手机号"
            ref="inputbox"
          >
            <template #prefix>
              <img src="@/assets/svg/search.svg" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item label="申请时间：" name="applyTime">
          <a-range-picker :disabledDate="disabledDate" separator="→" allowClear v-model:value="applyTime">
            <template #suffixIcon>
              <CalendarOutlined />
            </template>
          </a-range-picker>
        </a-form-item>
        <a-form-item class="form-btns">
          <a-button @click="reset">重置</a-button>
          <a-button class="form-btn" @click="searchList">查询</a-button>
        </a-form-item>
      </a-form>
    </section>

    <!-- 内容 -->
    <section style="margin: 24px" class="table-style">
      <div class="table-head">
        <span class="title">文件列表</span>
        <div class="btn-block">
          <span
            style="color: rgba(0,0,0,0.65);display: inline-block;margin-right: 8px;"
            v-if="selectedRowKeys.length > 0"
            >已经选择 {{ selectedRowKeys.length }} 项</span
          >
          <a-tooltip placement="top" :overlayStyle="{ width: '260px', 'max-width': '260px' }" class="tips-text">
            <template #title>
              <span style="display:block;text-align:center;">仅已完结的文件可支持下载存证报告</span>
            </template>
            <QuestionCircleFilled class="charts-icon" />
          </a-tooltip>
          <a-dropdown :trigger="['click']">
            <a-button :class="[selectedRowKeys.length != 0 ? 'add-btn' : 'button-disabled']" :disabled="selectedRowKeys.length === 0" :loading="loading" @click="start"
              ><i class="iconfont icon-xiazai head-export" />下载</a-button
            >
            <template #overlay>
              <a-menu v-if="isdownloads">
                <a-menu-item key="0" @click="batchDownloads(1)">下载盖印文件</a-menu-item>
                <a-menu-item key="1" @click="batchDownloads(2)">下载存证报告</a-menu-item>
                <a-menu-item key="2" @click="batchDownloads(3)">全部下载</a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </div>
      </div>
      <a-table
        :columns="Columns"
        :dataSource="dataSource"
        :pagination="pagination"
        @change="handleTableChange"
        :loading="tableLoading"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        rowKey="id"
        ref="tableRef"
        :scroll="{ x: 400 }"
      >
        <!-- 序号 -->
        <template #id="{ index }">{{ (pagination.index - 1) * pagination.pageSize + index + 1 }}</template>
        <!-- 接收方 -->
        <template #signatory="{text}">
          <span :title="text">{{ text ? text : '-' }}</span>
        </template>
        <!-- 签署状态 -->
        <template #status="{text}">
          <span :style="{ background: signStatus[text]?.color }" class="status-icon"></span>
          <span>{{ signStatus[text]?.status }}</span>
        </template>
        <!-- 操作 -->
        <template #action="{record}">
          <div :class="[(activeKey == 1 || activeKey == 0) ? 'action-box' : 'action-boxs']">
            <span v-if="record.status === 1" @click="sign(record)">签署</span>
            <span @click="fileDetail(record.id)">详情</span>
            <a-dropdown :trigger="['click']">
              <span @click="downloadItems(record)">下载</span>
              <template #overlay>
                <a-menu v-if="isDownloadItem && record.status === 3">
                  <a-menu-item key="0" @click="downloadCheckbox(1)">下载盖印文件</a-menu-item>
                  <a-menu-item key="1" @click="downloadCheckbox(2)">下载存证报告</a-menu-item>
                  <a-menu-item key="2" @click="downloadCheckbox(3)">全部下载</a-menu-item>
                </a-menu>
              </template>
            </a-dropdown>
            <!-- <span v-if="activeKey != 0 || activeKey != 1"></span> -->
          </div>
        </template>
      </a-table>
      <drawer :visible="visible" :current="current" @close="drawerClose" @goIframe="getIframe" is />
    </section>
  </div>

  <div v-if="isIframe" style="width: 100%; height: 20px;"></div>
  <div style="height: 100%; position: relative;" v-if="isIframe">
    <div style="cursor:pointer; opacity: 0;width: 80px;height: 50px;background-color: pink;position: absolute;top: 0px;left: 15px;border: 0;display: flex;align-items: center;" @click="closeIframe">
      <img style="width: 14px; height: 14px;margin: 0 0 0 10px;" src="@/assets/images/eleSeal/return-img.png" alt="">
    </div>
    <div class="close-box">
      <div class="cloce-title">点击关闭签署</div>
      <a-button @click="closeIframe">关闭</a-button>
    </div>
    <iframe width="90%" height="90%" :src="url"></iframe>
  </div>
</template>

<script>
import { onMounted, reactive, ref, watch, toRefs, computed } from 'vue'
import { paginationParams } from '@/utils/constData'
import { fileList, signUrl, downloadItem, batchDownload } from '@/apis/eleSeal.js'
import { Columns } from './columns'
import { signStatus } from '@/utils/constData.js'
import drawer from '../components/drawer.vue'
import moment from 'moment'
import { QuestionCircleFilled, CalendarOutlined } from '@ant-design/icons-vue'
export default {
  components: {
    QuestionCircleFilled,
    CalendarOutlined,
    drawer
  },
  setup() {
    const inputbox = ref(null)
    const state = reactive({
      activeKey: '0',
      Columns,
      dataSource: [], // 请求的数据
      pagination: {
        ...paginationParams
      },
      currentNum: 1,
      tableLoading: true, // 加载loading
      searchVal: '', // 搜索框内容
      applyTime: [], // 申请时间
      selectedRowKeys: [],
      loading: false,
      visible: false, // 详情抽屉开关
      current: undefined, // 弹框数据
      isIframe: false, // 是否展示iframe
      url: '', // 签署地址
      isDownloadItem: false, // 是否展示多个下载选项
      currentItem: {}, // 当前选择
      isdownloads: undefined, // 下载是否有多个
    })

    // 重置
    const reset = () => {
      state.searchVal = ''
      state.applyTime = []
      getList()
    }

    // 搜索框点击
    const clickFocus = () => {
      inputbox.value.focus()
    }

    // 查询
    const searchList = () => {
      state.selectedRowKeys = []
      state.pagination.current = 1
      getList()
    }

    // 签署
    const sign = async (item) => {
      // console.log('签署id', item.id)
      const res = await signUrl({
        id: item.id,
        url: { callbackPage: `${process.env.VUE_APP_PC_BASE_URL}/signedInstrument` }
      })
      if (res.code === 0) {
        state.url = res.data
        state.isIframe = true
      }
    }
    // 关闭iframe
    const closeIframe = () => {
      state.isIframe = false
      getList()
    }
    // 获取子组件的iframe
    const getIframe = id => {
      console.log('id', id)
      sign(id)
    }

    // 详情
    const fileDetail = id => {
      state.visible = true
      state.current = id
    }

    // 批量下载
    const batchDownloads = async check => {
      const res = await batchDownload({
        idList: state.selectedRowKeys,
        fileType: check
      })
      const link = document.createElement('a')
      // let blob = new Blob([res], { type: "application/zip" });  //文件流处理
      let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
      let blob = res.data //文件流处理
      link.style.display = 'none' //去除a标签的样式设置连接
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      // 模拟点击事件
      link.click()
    }

    // 列表下载
    const downloadItems = async item => {
      if (item.status === 3) {
        state.isDownloadItem = true
        state.currentItem = item
        return
      }
      const res = await downloadItem({ id: item.id, fileType: 1 })
      const link = document.createElement('a')
      // let blob = new Blob([res], { type: "application/zip" });  //文件流处理
      let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
      let blob = res.data //文件流处理
      link.style.display = 'none' //去除a标签的样式设置连接
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      // 模拟点击事件
      link.click()
    }
    // 下载已完成
    const downloadCheckbox = async check => {
      const item = state.currentItem
      const res = await downloadItem({ id: item.id, fileType: check })
      const link = document.createElement('a')
      // let blob = new Blob(res, { type: "application/zip" });  //文件流处理
      let fileName = decodeURIComponent(res.headers['content-disposition'].split('=')[1]) //拆解获取文件名，
      let blob = res.data //文件流处理
      link.style.display = 'none' //去除a标签的样式设置连接
      link.href = URL.createObjectURL(blob)
      link.download = fileName
      document.body.appendChild(link)
      // 模拟点击事件
      link.click()
    }

    // 抽屉关闭
    const drawerClose = () => {
      state.visible = false
    }

    const hasSelected = computed(() => state.selectedRowKeys.length > 0)
    const onSelectChange = selectedRowKeys => {
      console.log('选中: ', selectedRowKeys)
      state.selectedRowKeys = selectedRowKeys
    }
    const start = () => {
      if (state.selectedRowKeys.length === 0) return
      let datas = state.dataSource
      let ids = state.selectedRowKeys
      const newArr = datas.filter(item => ids.includes(item.id))
      console.log(newArr)
      state.isdownloads = newArr.find(item => {
        return item.status === 3
      })
      if (!state.isdownloads) {
        batchDownloads(0)
      }
    }

    // 日期选择器：当天之后不可选
    const disabledDate = current => {
      return current && current > moment().endOf('day')
    }

    // 换页
    const handleTableChange = ({ current, pageSize }) => {
      state.dataSource = []
      state.pagination.current = current
      state.currentNum = current
      state.pagination.pageSize = pageSize
      state.selectedRowKeys = []
      getList()
    }

    // 接口
    const getList = async () => {
      state.tableLoading = true
      let params = {
        isPagination: true,
        pageIndex: state.pagination.current,
        pageSize: state.pagination.pageSize,
        status: state.activeKey,
        search: state.searchVal,
        startDate: state.applyTime ? moment(state.applyTime[0]).format('YYYY-MM-DD') : '',
        endDate: state.applyTime ? moment(state.applyTime[1]).format('YYYY-MM-DD') : ''
      }
      if (!state.applyTime.length) {
        params.startDate = ''
        params.endDate = ''
      }
      const res = await fileList(params)
      if (res.code === 0) {
        state.dataSource = res.data
        state.pagination.total = res.totalItem
        state.pagination.index = res.pageIndex
      }
      // 结束后
      state.tableLoading = false
    }

    onMounted(() => {
      getList()
    })

    // 监听点击tab切换
    watch(
      () => state.activeKey,
      async newValue => {
        console.log('最新值', newValue)
        state.selectedRowKeys = []
        state.pagination.current = 1
        const res = await fileList({
          isPagination: true,
          pageIndex: '1',
          pageSize: state.pagination.pageSize,
          status: newValue,
          search: '',
          startDate: '',
          endDate: ''
        })
        if (res.code === 0) {
          state.dataSource = res.data
          state.pagination.total = res.totalItem
          state.pagination.index = res.pageIndex
        }
      }
    )

    return {
      ...toRefs(state),
      reset,
      searchList,
      handleTableChange,
      start,
      hasSelected,
      onSelectChange,
      disabledDate,
      clickFocus,
      inputbox,
      signStatus,
      sign,
      fileDetail,
      downloadItems,
      drawerClose,
      closeIframe,
      getIframe,
      downloadCheckbox,
      batchDownloads
    }
  }
}
</script>

<style lang="less" scoped>
html,
body {
  height: 100%;
}
iframe {
  display: block;
  border: none;
  height: calc(100vh - 150px);
  width: calc(100vw - 208px);
}
.seal-tabs {
  margin-top: -36px;
  :deep(.ant-tabs-nav-container) {
    background-color: #ffffff;
    padding: 0 24px;
  }
  :deep(.ant-tabs-nav .ant-tabs-tab) {
    padding: 13px 0;
    font-size: 14px;
  }
  :deep(.ant-tabs-bar) {
    margin: 0;
  }
  :deep(.ant-tabs-tabpane-active) {
    padding: 24px 24px 0;
  }
}
.charts-icon {
  font-size: 14px;
  color: #333333;
  cursor: pointer;
}

.dottos {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 7px;
}
.seal-list {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 250px;
}

.action-box {
  color: #1890ff;
  display: flex;
  justify-content: flex-end;

  span {
    margin: 0 8px 0 8px;
    cursor: pointer;
  }
}

.action-boxs {
  width: 80px;
  color: #1890ff;
  display: flex;
  justify-content: flex-end;

  span {
    margin: 0 8px 0 8px;
    cursor: pointer;
  }
}

// 签署状态
.status-icon {
  width: 6px;
  height: 6px;
  display: inline-block;
  margin: 0 8px 2px 0;
  border-radius: 3px;
}

.close-box {
  position: absolute;
  top: -35px;
  right: 24px;
  display: flex;
  align-items: center;
  .cloce-title {
    height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.45);
    line-height: 22px;
    margin-right: 8px;
  }
}
.button-disabled {
  width: 79px;
  height: 32px;
  margin-left: 16px;
  background: rgba(0,0,0,0.04);
  border-radius: 2px;
  border: 1px solid rgba(0,0,0,0.15);
  color: rgba(0,0,0,0.25);
  cursor:not-allowed;
  font-size: 14px;
  box-shadow: none;
  box-sizing: border-box; 
}

// 签署
.sign-box {
  width: 500px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #1A1A1B;
  line-height: 22px;
  margin-left: 5px;
  display: inline-block;
  background-color: #fff;
}
</style>
